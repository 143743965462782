<!--  -->
<template>
    <div class='page-task-action'>
        <exercise :sgf="sgf" 
        @gameover="gameover" 
        @nextExercise="exerciseHand"
        :awardCount.sync="awardCount"
        ref="exercise">
            <span slot="title-text" 
            class="text-normal max-width">第{{currentExNum}}/{{exerciseCount}}题</span>

            <div class="ex-hand-fun" slot="mask-extend" v-if="!nextExerciseData">
                <!-- <dia-button type="confirm" shape="square" class="flex-center" @click="exerciseHand">
                    <span class="text-normal-a">{{pageText.t1}}</span>
                </dia-button> -->
            </div>
        </exercise>
        <subResult ref="result" @restart="restart"></subResult>
    </div>
</template>

<script>
    import apiClass from '@/API/page/task'
    import exercise from '@/components/exercise/exercise.vue'
    import subResult from './subResult.vue'
    import timeCount from '@/components/exercise/timeCount.vue'

    export default {
        name: '',
        extends: timeCount,
        props: {},
        data() {
            return {
                _exercise: null,
                api: new apiClass(),
                routeData: this.$route.params.routeData,
                exerciseList: [],
                exerciseState: false,
                pageState: false,
                gameResult: null,
                exerciseAwardInfo: {},
            };
        },
        methods: {
            dataInit(){
                this.exerciseList = []
                this.exerciseState = false
                this.pageState = false
                this.gameResult = null
                this.timeCountInit()
            },
            async dataLoad(){
                await this.exerciseListInit()
                await this.exerciseValCheck()
            },
            exerciseValCheck(){
                let {id: exerciseId} = this.currentExercise || {}
                let {defaultExerciseId: defaultId} = this
                if(exerciseId || !defaultId) return
                let items = {exerciseId: defaultId}
                this.refreshPage({items})
            },
            async exerciseListInit(){
                let {sectionId, difficulty} = this
                let opt = {
                    section_id: sectionId,
                    difficulty
                }
                let res = await this.api.subjectExercise(opt)
                if(!res) return
                this.exerciseList = res
            },
            eventSet(){
                this.eventOff()
                this._common.eventSet('taskSubjectBackFun', e => {
                    this.back()
                })
            },
            eventOff(){
                this._common.eventRemove('taskSubjectBackFun')
            },
            back(){
                let { routeDataCul } = this
                let name = 'task-subject-section'
                let type = 'replace'
                let params = {...routeDataCul}
                params = {
                    routeData: this._common.encodeData(params)
                }
                this._routeHand.goto({name, type, params})
            },
            exerciseComInit(){
                let ele = this.$refs['exercise']
                if(!ele) return
                this._exercise = ele
                this.exerciseState = true
            },
            async gameover(opt){
                this.timeCountClear()
                let res = await this.answerUpload(opt)
                if(!res) return
                res.awardCount = res.awardCount || 0
                this.exerciseAwardInfo = {
                    exerciseId: opt.exerciseId,
                    awardCount: res.awardCount,
                }
                this._exercise.resultMaskOpen()
                let {
                    nextExerciseData: {id} = {}
                } = this
                if(!id) this.gameCount()
            },
            async answerUpload(opt){
                let {actual: userAnswer, correct, isCorrect} = opt
                correct = correct == userAnswer ? 1 : 0
                if(isCorrect !== undefined) correct = isCorrect ? 1 : 0
                let {exerciseId, usercode} = this
                let {time: answerDuration} = this
                let res = await this.api.subAnswerUpload({userAnswer, exerciseId, correct, usercode, answerDuration})
                return res
            },
            gameCount(){
                this.$refs['exercise'].stopCountdown()
                this.$refs['result'].open()
            },
            async nextExercise(){
                let {
                    nextExerciseData: {id: exerciseId} = {}
                } = this
                if(!exerciseId) return
                let items = {exerciseId: undefined}
                await this.refreshPage({items})
                items = {exerciseId}
                await this.refreshPage({items})
            },
            exerciseHand(){
                let {
                    nextExerciseData: {id: exerciseId} = {}
                } = this
                this.exerciseAwardInfo = {}
                return exerciseId ? this.nextExercise() : this.gameCount()
            },
            refreshPage(opt){
                opt = opt || {}
                let {items, name, type} = opt
                type = type || 'replace'
                return this._common.refreshPage.call(this, {items, name, type})
            },
            refreshPageAction(opt){
                opt = opt || {}
                let {name, params, type} = opt
                name = name || 'task-subject-action'
                type = type || 'replace'
                return this._common.refreshPageAction.call(this, {name, params, type})
            },
            setParams(items){
                return this._common.setParams.call(this, items)
            },
            async restart(){
                let {sectionId,defaultExerciseId} = this
                let items = {}
                items.sectionId = sectionId
                items.exerciseId = defaultExerciseId
                // this.dataInit()
                await this.refreshPage({items})
                // this.dataLoad()
            }
        },
        created() {
            this.eventSet()
        },
        components: {
            exercise,
            subResult,
        },
        computed: {
            difficulty(){
                let {difficulty} = this.routeDataCul
                return difficulty
            },
            awardCount(){
                return this.exerciseAwardInfo.awardCount || 0
            },
            routeDataCul(){
                let {routeData} = this
                let data =  this._common.routeDataCul(routeData)
                return data
            },
            sgf(){
                let {
                    currentExercise: {exerciseContent: sgf} = {}
                } = this
                return sgf
            },
            nextExerciseData(){
                let {exerciseListCul: list, currentExercise: curEx = {}} = this
                let rd = null
                for(let i in list){
                    if(list[i].id == curEx.id){
                        let tem = list[Number(i) + 1]
                        rd = tem
                    }
                }
                return rd
            },
            currentExNum(){
                let {exNum = 0} = this.currentExercise || {}
                return exNum
            },
            exerciseListCul(){
                let {exerciseList: list} = this
                list = this._common.deepCopy(list)
                for(let i in list){
                    list[i].exNum = Number(i) + 1
                }
                return list
            },
            exerciseId(){
                let {exerciseId} = this.routeDataCul
                return exerciseId
            },
            sectionId(){
                let {id: sectionId} = this.routeDataCul
                return sectionId
            },
            currentExercise(){
                let {exerciseListCul: list, exerciseId} = this
                let rd = {}
                for(let i in list){
                    let {id} = list[i] || {}
                    if(id == exerciseId){
                        rd = list[i]
                        break
                    }
                }
                return rd
            },
            exerciseCount(){
                return this.exerciseListCul.length || 0
            },
            usercode(){
                let {
                    app: {usercode} = {}
                } = this.$store.state
                return usercode
            },
            defaultExerciseId(){
                let {id: exerciseId} = this.exerciseListCul[0] || {}
                return exerciseId
            },
        },
        watch: {
            exerciseId: {
                handler(val, oldVal){
                    if(!val || val == oldVal) return
                    this.timeCountStart()
                },
                deep: true,
                immediate: true
            },
            $route: {
                handler(){
                    this.routeData = this.$route.params.routeData
                },
                deep: true,
                immediate: true
            },
            sectionId: {
                handler(val, oldval){
                    if(!val || val == oldval) return
                    this.dataLoad()
                },
                deep: true,
                immediate: true
            }
        },
        mounted() {
            this.exerciseComInit()
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.eventOff()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' scoped>
</style>